import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator/es';

import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';

// reducer - state management
import { LOGIN, LOGOUT, REFRESH } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';
import { WoAlert } from '../utils/kmwine-alerts';
import { doc, getDoc } from 'firebase/firestore';
import { firestoreDB } from '../App';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const AuthContext = createContext(null);

export const WineOneAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const navigate = useNavigate();
  // const location = useLocation();

  // 최초
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (firebaseUser) => {
      console.debug('[1KMADM] onAuthStateChanged', firebaseUser);

      if (firebaseUser) {
        const member = await getAdminFromFirestore(firebaseUser.uid).catch((error) => {
          console.error('[AuthContext] 관리자 정보 조회 실패.', error);
          return { error };
        });

        const { isLoggedIn } = state;

        // firebase 조회 오류
        if (member.error) {
          if (isLoggedIn) {
            WoAlert.fire('로그인 정보 갱신오류', '관리자 정보 조회 중 오류가 발생했습니다.<br/>잠시 후 다시 시도해주십시오.').then(() => {
              navigate(0);
            });
          }

          return undefined;
        }

        // 관리자 정보가 존재하지 않음
        if (!member) {
          await logout(() => {
            WoAlert.fire('올바르지 않은 계정', '관리자 정보가 존재하지 않습니다.<br/>로그아웃 처리됩니다.');
          });
        }
        // 관리자 정보가 존재함
        else {
          console.debug('로그인 관리자 정보: ', member);

          // 관리자 계정이 아님
          if (member.role !== 'admin') {
            WoAlert.fire(`올바르지 않은 계정', '관리자 권한이 아닙니다.<br/>로그아웃 처리됩니다.<br/><br/>[role='${member?.role}']`).then(
              () => {
                logout();
              }
            );
          }
          // 계정이 중지된 사용자
          else if (member.state !== 'OK') {
            WoAlert.fire(
              '올바르지 않은 계정',
              `사용이 중지된 계정입니다.<br/>로그아웃 처리됩니다.<br/><br/>[state='${member?.state}']`
            ).then(() => {
              logout();
            });
          } else {
            await dispatch({ type: LOGIN, payload: { user: member } });
          }
        }
      } else {
        console.warn('[1KMADM] 비로그인 사용자의 로그아웃처리');
        await logout();
      }
    });
    return () => unsubscribe();
  }, []);

  /**
   * 관리자 로그인
   *
   * @param {string} email 사용자 아이디 (이메일)
   * @param password 비밀번호
   * @param callback
   * @returns {Promise<void>}
   */
  const login = async (email, password, callback = () => {}) => {
    console.debug(`[AuthContext][@login] 로그인 시작. email='${email}'`);
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password).catch((error) => ({ error }));

    // 로그인 요청 중 오류 발생
    if (userCredential.error) {
      const { error } = userCredential;
      console.warn(`[AuthContext] 관리자 로그인 실패 'signInWithEmailAndPassword' error.`, error);
      callback(error);
      return undefined;
    }

    // Firebase authentication credential.
    const { user: firebaseUser } = userCredential;

    // claims 검사
    const idTokenResult = await firebaseUser.getIdTokenResult().catch((error) => ({ error }));

    // 토큰정보 조회 실패
    if (idTokenResult.error) {
      const { error } = idTokenResult;
      console.error('[1KMADM] 로그인 사용자 토큰조회 실패. ', error);
      callback(error);
      return undefined;
    }

    // Firebase user claims.
    const { claims } = idTokenResult;
    console.debug(`[AuthContext][@login] 로그인 시도한 사용자의 'claims' 체크.`, claims);

    // role이 관리자(admin)가 아닐 경우
    if (claims?.role !== 'admin') {
      await logout(); // 로그아웃 처리
      // 관리자 계정이 아닐경우, 계정 추측을 막기위해 로그인 사용자가 아니라는 token 처리
      console.warn(`[1KMADM] 'admin' 권한을 가지지 않은 사용자의 로그인 시도.`);
      callback({ code: 'not-existing-customer' });
      return undefined;
    }

    // 로그인 사용자의 파트 정보
    const part = claims?.part ?? undefined;

    // 파트 정보가 존재하지 않음
    if (!part) {
      console.error('[1KMADM] 로그인 사용자의 파트정보가 없습니다.');
      WoAlert.fire('올바르지 않은 계정', `로그인 사용자의 파트정보가 없습니다.<br />[part=${part}]`, 'error').then(() => {
        navigate(0);
      });
      return undefined;
    }

    console.log(`[1KMADM] 로그인 사용자의 파트정보: '${part}'`);

    // if (part === 'ADMIN') {
    //   navigate(ORDER_PATH, { replace: true });
    // } else {
    //   navigate(DATA_PATH, { replace: true });
    // }

    // 브라우저 로컬 퍼시스턴스 -> 로그인 상태 유지
    await setPersistence(auth, browserLocalPersistence);

    // 로그인 성공, 로그인 화면의 콜백 호출
    try {
      const member = await getAdminFromFirestore(firebaseUser.uid);
      const { state } = member;

      if (!state || state !== 'OK') {
        await logout();
        WoAlert.fire('올바르지 않은 계정', `사용이 중지된 계정입니다.<br/>로그아웃 처리됩니다.<br/><br/>[state='${state}']`).then(() => {
          navigate(0);
        });
        return undefined;
      }

      await dispatch({ type: LOGIN, payload: { user: { ...member, god: claims.god ?? false } } });
      callback(null);
    } catch (e) {
      console.log('------ 로그인 중 사용자 정보 조회 실패', e.code, Object.keys(e), e);
      callback(e);
    }
  }; // end of login

  /**
   * 관리자 로그아웃
   * @param callback
   * @returns {Promise<null>}
   */
  const logout = async (callback) => {
    let logoutResult = null;

    try {
      const auth = getAuth();
      logoutResult = await auth.signOut();
      console.debug('[1KMADMIN] 관리자 로그아웃 처리결과.', logoutResult);
    } catch (e) {
      console.warn('[1KMADMIN] 관리자 로그아웃 처리 중 오류가 발생했습니다.');
    } finally {
      await dispatch({ type: LOGOUT });
      if (typeof callback === 'function') {
        callback();
      }
    }

    return logoutResult;
  };

  /**
   * 관리자 정보 조회 from firestore
   * @param {string} uid firebase authentication userId(uid)
   * @returns {Promise<null|undefined|*>}
   */
  const getAdminFromFirestore = async (uid) => {
    if (validator.isEmpty(uid)) {
      console.warn(`'uid' not exists.`);
      return undefined;
    }

    // firestore에서 로그인 관리자(member) 조회
    const memberSnapshot = await getDoc(doc(firestoreDB, 'member', uid)).catch((error) => {
      console.warn('[1KMADM] 로그인 관리자 정보조회 중 오류 [EADM10001001].', `uid='${uid}'`, error);
      throw error;
    });

    if (!memberSnapshot || !memberSnapshot.exists()) {
      console.log(`[AuthContext] admin not exists. [uid=${uid}]`);
      return null;
    }

    // 관리자 member 정보
    return memberSnapshot.data();
  };

  /**
   * 로그인 사용자정보 갱신
   */
  const refresh = async () => {
    const auth = getAuth();
    const { currentUser } = auth; // 현재 로그인 사용자 firebase authentication

    // firestore에서 로그인 사용자정보(member) 조회
    const member = await getAdminFromFirestore(currentUser.uid).catch((error) => {
      console.warn('[1KMADM] 로그인사용자 정보조회 중 오류 [E174840].', error);
      throw error;
    });

    dispatch({ type: REFRESH, payload: { user: member } });

    return member;
  };

  const resetPassword = (email) => {
    console.log(`@reset password [id=${email}]`);
  };

  const updateProfile = () => {
    console.log('@update customer profile.');
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ ...state, login, logout, getAdminFromFirestore, resetPassword, updateProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

WineOneAuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
