// project imports
import PageNotFound from '../views/error/PageNotFound';

// ==============================|| MAIN ROUTING ||============================== //

/**
 * 에러화면 라우팅
 * @type {{path: string, element: JSX.Element}}
 *
 * @autho hkcho
 */
const ErrorRoutes = {
  path: '/',
  element: null,
  children: [
    {
      path: '/error/404',
      element: <PageNotFound />
    },
    {
      path: '/error/403',
      element: <PageNotFound />
    }
  ]
};
export default ErrorRoutes;
