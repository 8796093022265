import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH, DATA_PATH } from 'config';
import { getAuth } from 'firebase/auth';
import { WoAlert } from '../kmwine-alerts';

// ==============================|| GUEST GUARD ||============================== //

/**
 * 특정 권한을 가진 운영자만 접근할 수 있는 가드
 * @param {Array<string>} parts 접근가능한 권한 배열.
 * @param {PropTypes.node} children children element/node
 */
const PartGuard = ({ parts = [], children }) => {
  const { isLoggedIn, user } = useAuth();
  console.debug('[PartGuard] isLoggedIn?', isLoggedIn);
  const navigate = useNavigate();

  const [allowable, setAllowable] = React.useState(false);

  React.useEffect(() => {
    // 비로그인
    if (!isLoggedIn) {
      navigate('login', { replace: true });
    }
    // 지정된 파트가 없음
    else if (!Array.isArray(parts) || parts.length === 0) {
      throw new Error(`'part' cannot be null or empty.`);
    } else {
      const { part = 'forbidden' } = user;
      // 페이지 접근 가능 여부
      const inaccessible = parts.indexOf(part) >= 0;

      if (!inaccessible) {
        console.warn(`[PartGuard] 접근할 수 없는 페이지, 로그인한 관리자의 part='${part}'`);
        WoAlert.fire('접근 제한', '접근할 수 없는 페이지 입니다.', 'error').then(() => {
          navigate(DATA_PATH, { replace: true });
        });
      }

      setAllowable(inaccessible);
    }
  }, [isLoggedIn, parts, navigate]);

  return allowable ? children : null;
};

PartGuard.propTypes = {
  parts: PropTypes.array.isRequired,
  children: PropTypes.node
};

export default PartGuard;
