/*
 * Side Bar - 정산 관리
 */

import { Part } from 'config';
import { IconMathSymbols } from '@tabler/icons';

// ==============================|| CALCULATE MENU ITEMS ||============================== //

const calculate = {
  id: 'calculateTab',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'calculateTab',
      title: '정산관리',
      type: 'collapse',
      children: [
        {
          id: 'calculateVendor',
          title: '입점샵 정산',
          type: 'item',
          url: '/calculate-vendor',
          icon: IconMathSymbols,
          breadcrumbs: true
        },
        {
          id: 'calculateManage',
          title: '정산하기',
          type: 'item',
          url: '/calculate',
          icon: IconMathSymbols,
          breadcrumbs: true
        },
        {
          id: 'calculateHistory',
          title: '정산 내역',
          type: 'item',
          url: '/calculate-history',
          icon: IconMathSymbols,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default calculate;
