import { useContext } from 'react';
import { ConfigContext } from 'contexts/ConfigContext';

// ==============================|| CONFIG - HOOKS  ||============================== //

console.log(ConfigContext);

const useConfig = () => useContext(ConfigContext);

export default useConfig;
