/*
 * Side Bar - 포스트 관리
 */

import { Part } from 'config';
import { IconBellRinging, IconBook, IconLetterQ, IconStar } from '@tabler/icons';

// ==============================|| POST MENU ITEMS ||============================== //

const post = {
  id: 'post',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'postTab',
      title: '포스트 관리',
      type: 'collapse',
      children: [
        {
          id: 'postMainMagazine',
          title: '메인 콘텐츠 관리',
          type: 'item',
          url: '/post/main-magazine',
          icon: IconBook,
          breadcrumbs: true
        },
        {
          id: 'postReviewManage',
          title: '리뷰 관리',
          type: 'item',
          url: '/post/review',
          icon: IconStar,
          breadcrumbs: true
        },
        {
          id: 'postFaqManage',
          title: 'FAQ 관리',
          type: 'item',
          url: '/post/faq',
          icon: IconLetterQ,
          breadcrumbs: true
        },
        {
          id: 'postNoticeManage',
          title: '공지사항 관리',
          type: 'item',
          url: '/post/notice',
          icon: IconBellRinging,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default post;
