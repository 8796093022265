/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => (
  <svg width="90%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.56 30.09">
    <g className="cls-1">
      <g>
        <g>
          <polygon
            className="cls-3"
            points="136.03 1.8 126.47 1.8 114.82 12.91 118.46 1.8 110.38 1.8 101.7 28.29 109.78 28.29 116.29 21.72 119.24 28.29 127.31 28.29 121.84 16.11 136.03 1.8"
          />
          <polygon
            className="cls-3"
            points="246.26 1.8 241.59 16.04 236.92 1.8 228.8 1.8 220.11 28.29 228.19 28.29 232.86 14.03 237.53 28.29 245.65 28.29 254.33 1.8 246.26 1.8"
          />
          <polygon
            className="cls-3"
            points="172.53 28.29 163.84 1.8 155.77 1.8 151.1 16.04 146.44 1.8 138.31 1.8 129.63 28.29 137.7 28.29 142.37 14.03 147.05 28.29 155.16 28.29 159.81 14.12 164.45 28.29 172.53 28.29"
          />
          <polygon
            className="cls-3"
            points="203.29 1.8 198.63 16.04 193.96 1.8 185.89 1.8 185.89 1.8 181.24 15.98 176.59 1.8 168.52 1.8 177.2 28.29 185.28 28.29 189.92 14.12 194.57 28.29 202.68 28.29 211.36 1.8 203.29 1.8"
          />
          <polygon className="cls-3" points="216.05 1.8 224.12 1.8 215.44 28.29 207.36 28.29 216.05 1.8" />
          <polygon className="cls-3" points="96.15 1.8 89.54 8.1 95.57 8.1 88.95 28.29 97.03 28.29 105.71 1.8 96.15 1.8" />
          <polygon
            className="cls-3"
            points="281.48 8.09 283.56 1.8 259.01 1.8 250.32 28.29 274.81 28.29 276.89 22 260.46 22 261.71 18.19 278.15 18.19 280.23 11.9 263.77 11.9 265.02 8.09 281.48 8.09"
          />
        </g>
        <g>
          <polygon
            className="cls-3"
            points="69.19 0 63.88 16.21 58.57 .01 58.57 0 58.57 0 49.4 0 49.39 0 49.4 .01 44.13 16.07 38.87 0 38.78 0 29.7 0 29.61 0 24.32 16.14 19.03 0 19.03 0 19.03 0 9.86 0 9.86 0 9.86 0 0 30.09 9.17 30.09 14.45 13.99 19.72 30.09 19.75 30.09 28.89 30.09 28.92 30.09 34.24 13.86 39.55 30.06 39.54 30.09 39.56 30.09 48.71 30.09 48.73 30.09 48.72 30.06 53.98 14 59.26 30.09 59.33 30.09 68.43 30.09 68.5 30.09 78.36 0 69.19 0"
          />
          <polygon className="cls-2" points="19.03 0 14.45 13.99 19.72 30.09 24.32 16.14 19.03 0" />
          <polygon className="cls-2" points="38.86 0 34.28 13.99 39.55 30.09 44.15 16.14 38.86 0" />
          <polygon className="cls-2" points="58.56 0 53.97 13.99 59.25 30.09 63.85 16.14 58.56 0" />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
