/*
 * Side Bar - 입점샵 관리
 */

import { IconBuildingStore, IconChartDots, IconHourglass, IconSortAscending } from '@tabler/icons';
import { Part } from '../config';

// ==============================|| VENDOR MENU ITEMS ||============================== //

const vendor = {
  id: 'vendor',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin, Part.Observer],
  children: [
    {
      id: 'vendorTab',
      title: '입점샵 관리',
      type: 'collapse',
      part: [Part.Admin, Part.SubAdmin, Part.Observer],
      children: [
        {
          id: 'vendorManage',
          title: '입점샵 관리',
          type: 'item',
          url: '/vendor/list',
          icon: IconBuildingStore,
          breadcrumbs: true
        },
        {
          id: 'notVendorManage',
          title: '미입점샵 관리',
          type: 'item',
          url: '/not-vendor/list',
          icon: IconHourglass,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        },
        {
          id: 'vendorRecommendProduct',
          title: '추천상품 관리',
          type: 'item',
          url: '/vendor/product/recommend/edit',
          icon: IconSortAscending,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        }
        // {
        //   id: 'vendorStatisticsManage',
        //   title: '입점샵 통계',
        //   type: 'item',
        //   url: '',
        //   icon: IconChartDots,
        //   breadcrumbs: true,
        //   part: [Part.Admin, Part.SubAdmin]
        // }
      ]
    }
  ]
};

export default vendor;
