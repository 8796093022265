// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import wineOneReducer from './slices/wine-one';
import menuReducer from './slices/menu';
import snackbarReducer from './slices/snackbar';
import woConstantReducer from './slices/wo-constants';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  wineOne: persistReducer(
    {
      key: 'wineOne',
      storage
    },
    wineOneReducer
  ),
  menu: menuReducer,
  woConstants: persistReducer(
    {
      key: 'wo-const',
      storage
    },
    woConstantReducer
  )
});

export default reducer;
