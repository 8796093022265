// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  paddingLeft: 16,
  paddingTop: 16,
  paddingBottom: 16,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: '0 6px'
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
    marginRight: '5px'
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
    height: 28
  };

  return (
    <List
      sx={{
        width: '100%',
        minWidth: 330,
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          minWidth: 300,
          maxWidth: 300
        },
        '& .MuiDivider-root': {
          my: 0
        },
      }}
    >
      <ListItemWrapper>
        <ListItem alignItems='center'>
          <ListItemText primary='주문 승인 요청' />
          <ListItemSecondaryAction>
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12}>
                <Typography variant='caption' display='block' gutterBottom>오후 6:01</Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction='column' className='list-container'>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>몬테스 알파 카버네 쇼비뇽 750ml 외 1건</Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>
      <Divider />
      <ListItemWrapper>
        <ListItem alignItems='center'>
          <ListItemText primary='반품 요청' />
          <ListItemSecondaryAction>
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12}>
                <Typography variant='caption' display='block' gutterBottom>오후 5:57</Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction='column' className='list-container'>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>킴 크로포디 샤도네이 750ml</Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>

      <Divider />

      <ListItemWrapper>
        <ListItem alignItems='center'>
          <ListItemText primary='교환 요청' />
          <ListItemSecondaryAction>
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12}>
                <Typography variant='caption' display='block' gutterBottom>오후 5:26</Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction='column' className='list-container'>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>덕혼 디코이 피노누아 750ml</Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>

      <Divider />

      <ListItemWrapper>
        <ListItem alignItems='center'>
          <ListItemText primary='주문 승인 요청' />
          <ListItemSecondaryAction>
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12}>
                <Typography variant='caption' display='block' gutterBottom>오후 5:12</Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction='column' className='list-container'>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>드 샹세니 크레망 드 루이르 브뤼 750ml</Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>

      <Divider />

      <ListItemWrapper>
        <ListItem alignItems='center'>
          <ListItemText primary='주문 승인 요청' />
          <ListItemSecondaryAction>
            <Grid container justifyContent='flex-end'>
              <Grid item xs={12}>
                <Typography variant='caption' display='block' gutterBottom>오후 5:12</Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction='column' className='list-container'>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>드 샹세니 크레망 드 루이르 브뤼 750ml</Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>
    </List>
  );
};

export default NotificationList;
