import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PartGuard from '../utils/route-guard/PartGuard';
import { Part } from '../config';

// 주문관리 페이지
const OrderAllList = Loadable(lazy(() => import('views/order/AllList')));
const OrderList = Loadable(lazy(() => import('views/order/List')));
const OrderCancelList = Loadable(lazy(() => import('views/order/CancelList')));
const OrderExchangeList = Loadable(lazy(() => import('views/order/ExchangeList')));
const OrderRefundList = Loadable(lazy(() => import('views/order/RefundList')));
const OrderDetail = Loadable(lazy(() => import('views/order/detail/OrderDetail')));

/**
 * 주문관리 라우트
 * 접근 가능한 part: [ADMIN]
 *
 * @authors 이재일<leeji@wineone.io>
 */
const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <PartGuard parts={[Part.Admin, Part.SubAdmin, Part.Observer]}>
        <MainLayout />
      </PartGuard>
    </AuthGuard>
  ),
  children: [
    {
      // 주문 관리
      path: '/order',
      element: <OrderList />
    },
    {
      // 주문 전체내역
      path: '/order/all',
      element: <OrderAllList />
    },

    {
      // 주문취소 관리
      path: '/order/cancel',
      element: <OrderCancelList />
    },
    {
      // 교환 관리
      path: '/order/exchange',
      element: <OrderExchangeList />
    },
    {
      // 반품 관리
      path: '/order/refund',
      element: <OrderRefundList />
    },
    {
      // 주문 상세
      path: '/order/detail/:id',
      element: <OrderDetail />
    }
  ]
};

export default MainRoutes;
