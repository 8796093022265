/*
 * Side Bar - 이벤트/혜택관리
 */

import { Part } from 'config';
import { IconAd, IconGift, IconTicket, IconSpeakerphone, IconMessage, IconLollipop } from '@tabler/icons';

// ==============================|| EVENT&BENEFIT MENU ITEMS ||============================== //

const benefit = {
  id: 'event',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'eventTab',
      title: '이벤트/혜택관리',
      type: 'collapse',
      children: [
        {
          id: 'eventBannerManage',
          title: '배너 관리',
          type: 'item',
          url: '/benefit/banner',
          icon: IconAd,
          breadcrumbs: true
        },
        {
          id: 'eventCouponManage',
          title: '쿠폰 관리',
          type: 'item',
          url: '/benefit/coupon',
          icon: IconTicket,
          breadcrumbs: true
        },
        {
          id: 'eventManage',
          title: '이벤트 전시관리',
          type: 'item',
          url: '/benefit/event',
          icon: IconGift,
          breadcrumbs: true
        },
        {
          id: 'promotionManage',
          title: '프로모션 관리',
          type: 'item',
          url: '/benefit/promotion',
          icon: IconSpeakerphone,
          breadcrumbs: true
        },
        {
          id: 'pushManage',
          title: '푸쉬메시지 관리',
          type: 'item',
          url: '/benefit/push',
          icon: IconMessage,
          breadcrumbs: true
        },
        {
          id: 'mainPopupManage',
          title: '메인 팝업 관리',
          type: 'item',
          url: '/benefit/main-popup',
          icon: IconLollipop,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default benefit;
