/*
 * Side Bar - 상품 관리
 */

import { Part } from 'config';
import { IconGlass, IconGlassFull } from '@tabler/icons';

// ==============================|| PRODUCT MENU ITEMS ||============================== //

const product = {
  id: 'product',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin, Part.Observer],
  children: [
    {
      id: 'productTab',
      title: '상품관리',
      type: 'collapse',
      part: [Part.Admin, Part.SubAdmin, Part.Observer],
      children: [
        {
          id: 'productManage',
          title: '전체 상품 관리',
          type: 'item',
          url: '/product',
          icon: IconGlass,
          breadcrumbs: true
        },
        // 메인진열 카테고리 관리
        {
          id: 'homeCurationManage',
          title: '메인진열 카테고리 관리',
          type: 'item',
          url: '/product/home-curation',
          icon: IconGlassFull,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        },
        // 메인 진열 카드 관리
        {
          id: 'ThemeProductManage',
          title: '테마 상품 관리',
          type: 'item',
          url: '/product/theme',
          icon: IconGlassFull,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        }
      ]
    }
  ]
};

export default product;
