import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import image from 'assets/images/maintenance/img-build.svg';
import imageBackground from 'assets/images/maintenance/img-bg-grid.svg';
import imageDarkBackground from 'assets/images/maintenance/img-bg-grid-dark.svg';
import imageParts from 'assets/images/maintenance/img-bg-parts.svg';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';

// styles
const CardMediaWrapper = styled('div')({
  maxWidth: 720,
  margin: '0 auto',
  position: 'relative'
});

const PageContentWrapper = styled('div')({
  maxWidth: 350,
  margin: '0 auto',
  textAlign: 'center'
});

const ConstructionCard = styled(Card)({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const CardMediaBuild = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '5s bounce ease-in-out infinite'
});

const CardMediaParts = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '10s blink ease-in-out infinite'
});

// ========================|| UNDER CONSTRUCTION PAGE ||======================== //

const UnexpectedError = ({ error, resetErrorBoundary = null }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  console.error('[글로벌에러] 감지된 에러 => ', error);

  // render
  return (
    <ConstructionCard>
      <CardContent>
        <Grid container justifyContent="center" spacing={gridSpacing}>
          <Grid item xs={12}>
            <CardMediaWrapper>
              <CardMedia
                component="img"
                image={theme.palette.mode === 'dark' ? imageDarkBackground : imageBackground}
                title="Slider 3 image"
              />
              <CardMediaParts src={imageParts} title="Slider 1 image" />
              <CardMediaBuild src={image} title="Slider 2 image" />
            </CardMediaWrapper>
          </Grid>
          <Grid item xs={12}>
            <PageContentWrapper>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="div">
                    알 수 없는 오류가 발생했습니다.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    알 수 없는 오류가 발생했습니다.
                    <br />
                    같은 현상이 반복될 경우 담당자에게 연락바랍니다.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container xs={12} spacing="16px">
                    <Grid item xs={6}>
                      <AnimateButton>
                        <Button
                          fullWidth
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            if (typeof resetErrorBoundary === 'function') {
                              resetErrorBoundary();
                            }
                            navigate(-1);
                          }}
                        >
                          <ReplayTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> 뒤로가기
                        </Button>
                      </AnimateButton>
                    </Grid>

                    <Grid item xs={6}>
                      <AnimateButton>
                        <Button
                          fullWidth
                          variant="contained"
                          size="large"
                          onClick={() => {
                            if (typeof resetErrorBoundary === 'function') {
                              resetErrorBoundary();
                            }
                            navigate(DASHBOARD_PATH);
                          }}
                        >
                          <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> 홈으로
                        </Button>
                      </AnimateButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageContentWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ConstructionCard>
  );
};

export default UnexpectedError;
