import { useEffect } from 'react';
import axios from 'axios';

// routing
import Routes from 'routes';

// project imports
import ThemeCustomization from 'themes';

import { WineOneProvider } from './contexts/WineOneContext';
import { WineOneAuthProvider as AuthProvider } from 'contexts/AuthContext';

import { ConfigProvider } from 'contexts/ConfigContext';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { useDispatch } from './store';
import { setCodeVersion } from 'store/slices/wo-constants';
import { createBrowserHistory } from 'history';
import { getAuth } from 'firebase/auth';
import moment from 'moment/moment';
import Snackbar from 'components/Snackbar';

// ==============================|| APP ||============================== //

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // 1km wine 코드정보 가져오기
    dispatch(setCodeVersion(process.env.REACT_APP_WO_CODE_VERSION));

    // visibility 이벤트 감지
    window.onload = windowOnload;
    return () => {
      window.onload = null;
    };
  }, []);

  return (
    <WineOneProvider>
      <ConfigProvider>
        <ThemeCustomization>
          <AuthProvider>
            <Routes />
            <Snackbar />
          </AuthProvider>
        </ThemeCustomization>
      </ConfigProvider>
    </WineOneProvider>
  );
}

export default App;

export const history = createBrowserHistory();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

/* 관리자 화면은 Google Analytics 사용 안함
if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
}
*/

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });
export const firestoreDB = getFirestore(firebaseApp);

// Initialize Firebase storage
export const storage = getStorage(firebaseApp);

function windowOnload() {
  // check the visiblility of the page
  let hidden;
  let visibilityState;
  let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
    visibilityState = 'visibilityState';
  } else if (typeof document.mozHidden !== 'undefined') {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
    visibilityState = 'mozVisibilityState';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
    visibilityState = 'msVisibilityState';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
    visibilityState = 'webkitVisibilityState';
  }

  if (typeof document.addEventListener === 'undefined' || typeof hidden === 'undefined') {
    /* DO NOTHING */
  } else {
    document.addEventListener(
      visibilityChange,
      function () {
        console.debug(`[1KMADM] visibility changed. state=${visibilityState}`);
        const auth = getAuth();

        switch (document[visibilityState]) {
          case 'visible':
            if (auth.currentUser) {
              try {
                const { accessToken, stsTokenManager } = auth.currentUser;

                // 로그인 사용자 토큰
                // console.debug('#auth.currentUser: ', accessToken);
                if (stsTokenManager.isExpired) {
                  console.debug('[1KMADM] 관리자 토큰이 만료되었습니다. 토큰을 갱신합니다.');
                  auth.currentUser.getIdTokenResult(true).then(() => {
                    console.debug('[1KMADM:App.js] 관리자 access token을 갱신했습니다.');
                  });
                } else {
                  const expirationMmt = moment(stsTokenManager.expirationTime);
                  console.debug(`[1KMADM] 토큰이 아직 유효합니다. 토큰 만료시간='${expirationMmt.format('YY-MM-DD HH:mm')}'`);
                  // 과다한 호출로 limit이 걸릴 수 있어 필요이상의 토큰갱신은 주석처리
                  // auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
                  //   console.debug(
                  //     `[1KMADM] 토큰갱신 -> 갱신된 토큰의 만료시간='${moment(idTokenResult.expirationTime).format('YY-MM-DD HH:mm')}'`
                  //   );
                  // });
                }
              } catch (e) {
                /* DO NOTHING */
              }
            }

            break;
          case 'hidden':
            // hidden
            break;
          default: // nothing
        }
      },
      false
    );
  }
}

axios.interceptors.request.use(async (config) => {
  const auth = getAuth();

  if (auth.currentUser) {
    const { accessToken, stsTokenManager } = auth.currentUser;
    let bearerToken = accessToken;
    // 토큰이 만료되었을 경우 갱신
    if (stsTokenManager.isExpired) {
      console.debug('[axios.js] 관리자 토큰이 만료되어 갱신합니다.');
      const { token } = await auth.currentUser.getIdTokenResult(true);
      bearerToken = token;
      console.debug(`[axios.js]  ㄴ 갱신된 토큰=${bearerToken}`);
    }

    config.headers.Authorization = `Bearer ${bearerToken}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});
