import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import useAuth from '../../../../../hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

/**
 * Nav 그룹
 * @param item
 * @authors 이재일<leeji@wineone.io>
 *          hkcho<hkcho@wineone.io>
 */
const NavGroup = ({ item }) => {
  const theme = useTheme();
  const { user } = useAuth();

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    if (!menu.part || menu.part.includes(user?.part)) {
      switch (menu.type) {
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} />;
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    } else {
      // 해당 메뉴에 대한 권한이 없는 경우
      return null;
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.menuCaption }}
              display="block"
              gutterBottom
              style={{ fontWeight: '600' }}
            >
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
