import { Navigate, useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import PromotionRoutes from './PromotionRoutes';
import MyRoutes from './MyRoutes';
import PdataRoutes from './PdataRoutes';
import ProductRoutes from './ProductRoutes';
import OrderRoutes from './OrderRoutes';

import { DATA_PATH, ORDER_PATH, Part } from '../config';
import ErrorRoutes from './ErrorRoutes';
import useAuth from '../hooks/useAuth';
import React from 'react';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { user } = useAuth();
  const isAdmin = React.useMemo(() => user?.part === Part.Admin || user?.part === Part.SubAdmin, [user?.part]);

  return useRoutes([
    { path: '/', element: <Navigate to={isAdmin ? ORDER_PATH : DATA_PATH} replace /> },
    LoginRoutes,
    MainRoutes,
    MyRoutes,
    PdataRoutes,
    ProductRoutes,
    PromotionRoutes,
    OrderRoutes,
    ErrorRoutes,
    {
      path: '*',
      element: <Navigate to="/error/404" replace />
    }
  ]);
}
