import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// project imports
import { DATA_PATH, ORDER_PATH, Part } from 'config';
import Logo from 'ui-component/Logo';
import useAuth from 'hooks/useAuth';

// material-ui
import { Link } from '@mui/material';

// ==============================|| MAIN LOGO ||============================== //

export default function LogoSection() {
  const { user } = useAuth();
  const isAdmin = React.useMemo(() => user?.part === Part.Admin || user?.part === Part.SubAdmin, [user?.part]);

  return (
    <Link component={RouterLink} to={isAdmin ? ORDER_PATH : DATA_PATH} sx={{ lineHeight: 1 }}>
      <Logo />
    </Link>
  );
}
