import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import { PartGuard } from 'utils/route-guard/v2';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Part } from '../config';

// 전체 상품 관리 페이지
const ProductListPage = Loadable(lazy(() => import('views/product/ProductList')));
const ProductDetail = Loadable(lazy(() => import('views/product/ProductDetail')));
const ProductCreate = Loadable(lazy(() => import('views/product/ProductCreate')));
const ProductUpdate = Loadable(lazy(() => import('views/product/ProductUpdate')));

// 홈 큐레이션(메인진열 카테고리) 페이지
const HomeCuration = Loadable(lazy(() => import('views/product/homeCuration/HomeCuration')));
const HomeCurationCreate = Loadable(lazy(() => import('views/product/homeCuration/HomeCurationCreate')));
const HomeCurationModify = Loadable(lazy(() => import('views/product/homeCuration/HomeCurationModify')));

// 테마 상품관리 페이지
const ThemeProductList = Loadable(lazy(() => import('views/product/ThemeProduct/ThemeProductList')));
const ThemeProductCreate = Loadable(lazy(() => import('views/product/ThemeProduct/ThemeProductCreate')));
const ThemeProductUpdate = Loadable(lazy(() => import('views/product/ThemeProduct/ThemeProductUpdate')));

// 셀프 프로모션 관리 페이지
const SelfPromotionList = Loadable(lazy(() => import('views/self-promotion/SelfPromotionList/SelfPromotionList')));
const SelfPromotionCreate = Loadable(lazy(() => import('views/self-promotion/SelfPromotionCreate/SelfPromotionCreate')));
const SelfPromotionUpdateLayout = Loadable(lazy(() => import('views/self-promotion/SelfPromotionUpdate/SelfPromotionUpdateLayout')));

// 오늘의 한병 관리 페이지
const TodayBottle = Loadable(lazy(() => import('views/product/TodayOneBottle')));
const TodayBottleDetail = Loadable(lazy(() => import('views/product/TodayOneBottle/components/TodayOneBottleDetail')));

/**
 * 상품관리 라우트
 * 접근 가능한 part: [ADMIN]
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ProductRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <PartGuard accepts={[Part.Admin, Part.SubAdmin, Part.Observer]}>
        <MainLayout />
      </PartGuard>
    </AuthGuard>
  ),
  children: [
    {
      // 상품 목록
      path: '/product',
      element: <ProductListPage />
    },
    {
      // 상품 상세
      path: '/product/detail/:id',
      element: <ProductDetail />
    },
    {
      // 상품 등록
      path: '/product/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <ProductCreate />
        </PartGuard>
      )
    },
    {
      // 상품 수정
      path: '/product/update/:id',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <ProductUpdate />
        </PartGuard>
      )
    },
    // 오늘의 한병 관리
    {
      path: '/product/today-one-bottle',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <TodayBottle />
        </PartGuard>
      )
    },
    // 오늘의 한병 관리
    {
      path: '/product/today-one-bottle/detail',
      element: <TodayBottleDetail />
    },
    {
      // 홈큐레이션(메인진열 카테고리) 목록
      path: '/product/home-curation',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <HomeCuration />
        </PartGuard>
      )
    },
    {
      // 홈 큐레이션(메인진열 카테고리) 추가
      path: '/product/home-curation/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <HomeCurationCreate />
        </PartGuard>
      )
    },
    {
      // 홈 큐레이션(메인진열 카테고리) 수정
      path: '/product/home-curation/:curation_id/modify',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <HomeCurationModify />
        </PartGuard>
      )
    },
    {
      // 테마 상품 관리
      path: '/product/theme',
      element: <ThemeProductList />
    },
    {
      // 테마 상품 추가
      path: '/product/theme/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <ThemeProductCreate />
        </PartGuard>
      )
    },
    {
      // 테마 상품 수정
      path: '/product/curation-card/:cardId',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <ThemeProductUpdate />
        </PartGuard>
      )
    },
    {
      // 프로모션 관리
      path: '/self-promotion',
      element: <SelfPromotionList />
    },
    {
      // 프로모션 추가
      path: '/self-promotion/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <SelfPromotionCreate />
        </PartGuard>
      )
    },
    {
      // 프로모션 수정
      path: '/self-promotion/update/:id',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <SelfPromotionUpdateLayout />
        </PartGuard>
      )
    }
  ]
};

export default ProductRoutes;
