/*
 * Side Bar - 메뉴 관리
 */

import dashboard from './dashboard';
import tempSideBar from './tempSideBar';
import product from './product';
import promotion from './promotion';
import order from './order';
import benefit from './benefit';
import calculate from './calculate';
import vendor from './vendor';
import admin from './admin';
import user from './user';
import inquiry from './inquiry';
import statistics from './statistics';
import data from './data';
import post from './post';
import policy from './policy';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard,
    tempSideBar,
    product,
    promotion,
    order,
    calculate,
    benefit,
    vendor,
    admin,
    user,
    inquiry,
    statistics,
    data,
    post,
    policy
  ]
};

export default menuItems;
