import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// 마이페이지
const MyPage = Loadable(lazy(() => import('views/my/index')));

// 테마페이지
const ThemePage = Loadable(lazy(() => import('views/theme/index')));

/**
 * 마이페이지 라우트
 * 접근 가능한 part: [ADMIN, SUB, WINEDATA, DATACHECKER]
 *
 * @authors 이재일<leeji@wineone.io>
 */
const MyRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      // 마이페이지
      path: '/my',
      element: <MyPage />
    },
    {
      // 테마 설정
      path: '/theme',
      element: <ThemePage />
    }
  ]
};

export default MyRoutes;
