import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';
import AuthGuard from '../utils/route-guard/AuthGuard';
import PartGuard from '../utils/route-guard/PartGuard';
import MainLayout from '../layout/MainLayout';
import { Part } from '../config';

// 공동 구매 관리 페이지
const CoBuyingList = Loadable(lazy(() => import('views/co-buy/CoBuyingList')));
const CoBuyingCreate = Loadable(lazy(() => import('views/co-buy/CoBuyingCreate')));
const CoBuyingUpdate = Loadable(lazy(() => import('views/co-buy/CoBuyingUpdate')));
const CoBuyingDetail = Loadable(lazy(() => import('views/co-buy/CoBuyingDetail')));

/**
 * 공동구매 라우트 라우트
 * 접근 가능한 part: [ADMIN, SUB]
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PromotionRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <PartGuard parts={[Part.Admin, Part.SubAdmin]}>
        <MainLayout />
      </PartGuard>
    </AuthGuard>
  ),
  children: [
    // {
    //   // 셀프 프로모션 관리
    //   path: '/promotion/self',
    //   element: <SelfPromotionList />
    // },
    // {
    //   // 셀프 프로모션 추가
    //   path: '/promotion/self/create',
    //   element: <PromotionCreate />
    // },
    // {
    //   // 셀프 프로모션 수정
    //   path: '/promotion/self/:id',
    //   element: <SelfPromotionUpdateLayout />
    // },
    // // 오늘의 한병 목록
    // {
    //   path: '/promotion/today-one-bottle',
    //   element: <TodayBottle />
    // },
    // // 오늘의 한병 상세
    // {
    //   path: '/promotion/today-one-bottle/detail',
    //   element: <TodayBottleDetail />
    // },
    // 공동 구매 목록
    {
      path: '/promotion/co-buying',
      element: <CoBuyingList />
    },
    // 공동 구매 추가
    {
      path: '/promotion/co-buying/create',
      element: <CoBuyingCreate />
    },
    // 공동 구매 상세
    {
      path: '/promotion/co-buying/:id',
      element: <CoBuyingDetail />
    },
    // 공동 구매 수정
    {
      path: '/promotion/co-buying/update/:id',
      element: <CoBuyingUpdate />
    }
  ]
};

export default PromotionRoutes;
