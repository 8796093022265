/*
 * Side Bar - 주문 관리
 */

import { IconArrowBackUp, IconBasket, IconClipboardCheck, IconClipboardX, IconReplace } from '@tabler/icons';
import { Part } from '../config';

// ==============================|| ORDER MANAGE MENU ITEMS ||============================== //

const order = {
  id: 'orderTab',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin, Part.Observer],
  children: [
    {
      id: 'orderTab',
      title: '주문관리',
      type: 'collapse',
      part: [Part.Admin, Part.SubAdmin, Part.Observer],
      children: [
        {
          id: 'orderAll',
          title: '전체주문내역',
          type: 'item',
          url: '/order/all',
          icon: IconBasket,
          breadcrumbs: true
        },
        {
          id: 'orderManage',
          title: '주문 관리',
          type: 'item',
          url: '/order',
          icon: IconClipboardCheck,
          breadcrumbs: true
        },
        {
          id: 'orderCancelManage',
          title: '주문취소 관리',
          type: 'item',
          url: '/order/cancel',
          icon: IconClipboardX,
          breadcrumbs: true
        },
        {
          id: 'orderExchangeManage',
          title: '교환 관리',
          type: 'item',
          url: '/order/exchange',
          icon: IconReplace,
          breadcrumbs: true
        },
        {
          id: 'orderReturnManage',
          title: '반품 관리',
          type: 'item',
          url: '/order/refund',
          icon: IconArrowBackUp,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default order;
