import React from 'react';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

// project imports
import useAuth from 'hooks/useAuth';
import { Box, Typography } from '@mui/material';

/**
 * Guard component that conditionally renders its children based on user's part.
 * If both accepts and excepts arrays are empty, always renders the children.
 * If the authentication is not initialized, renders a loading message.
 * If user's part is included in accepts and not included in excepts, renders the children.
 * Otherwise, renders the fallback component or navigates to error page.
 *
 * @param {Object} props - The properties.
 * @param {ReactNode} props.children - The components to be rendered conditionally.
 * @param {Array} [props.accepts=[]] - The list of parts that are allowed to render the children.
 * @param {Array} [props.excepts=[]] - The list of parts that are not allowed to render the children.
 * @param {ReactNode} [props.loading] - The component to be rendered while authentication is not initialized.
 * @param {boolean} [props.component=false] - Flag indicating whether the PartGuard is used as a wrapper component.
 * @param {ReactNode} props.fallback - The component to be rendered as fallback if the user's part is not allowed.
 * @returns {Element} - The rendered component.
 * @constructor
 */
function PartGuard({ children, accepts = [], excepts = [], loading, component = false, fallback }) {
  const auth = useAuth();

  // If accepts and excepts are empty, always render the children
  if (accepts.length === 0 && excepts.length === 0) {
    // Notice that this PartGuard is useless if both accepts and excepts are empty.
    console.warn('PartGuard is useless if both accepts and excepts are empty');
    return <>{fallback ?? <Navigate to="/error/403" replace />}</>;
  }

  // If the authentication is not initialized, render a loading message
  if (!auth.isInitialized) {
    return (
      loading ?? (
        <Box tabIndex={-1} p={2} display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
          <Typography variant="caption">권한을 확인 중 입니다</Typography>
        </Box>
      )
    );
  }

  // Can render if the user's part is included in the accepts list and not included in the excepts list
  let render = false;
  const { user } = auth;
  if (accepts.length > 0 && excepts.length > 0) {
    render = accepts.includes(user?.part) && !excepts.includes(user?.part);
  } else if (accepts.length > 0) {
    render = accepts.includes(user?.part);
  } else if (excepts.length > 0) {
    render = !excepts.includes(user?.part);
  }

  // eslint-disable-next-line no-nested-ternary
  return <>{render ? children : component ? null : fallback ?? <Navigate to="/error/403" replace />}</>;
}

PartGuard.propTypes = {
  children: PropTypes.node,
  accepts: PropTypes.arrayOf(PropTypes.string),
  excepts: PropTypes.arrayOf(PropTypes.string),

  loading: PropTypes.oneOf([PropTypes.node, PropTypes.string]),

  component: PropTypes.bool,
  fallback: PropTypes.any
};

export default PartGuard;
