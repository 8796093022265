/**
 * FIREBASE API HOSTNAME
 */
export const FIREBASE_HOSTNAME = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;

// basename: only at build time to set, and Don't add '/' at ends off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

// 대시보드 경로
export const DASHBOARD_PATH = '/dashboard/default';
// 전체주문내역 경로
export const ORDER_PATH = '/order/all';
// 데이터 작성 경로
export const DATA_PATH = '/data/wine';

const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

const config = {
  // fontFamily: `'Roboto', Noto Sans KR`,
  fontFamily: `'NanumSquare', sans-serif, 'Noto Sans KR', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  // presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'ko', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese, 'ko' - Korean
  rtlLayout: false,
  container: false
};

export default config;

export const storage_bucket = {
  common: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}common.1kmwine.com`,
  vendor: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}vendor.1kmwine.com`,
  pdata: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}pdata.1kmwine.com`
};

/**
 * Represents the roles or types of parts in a system.
 * @readonly
 * @enum {string}
 */
export const Part = Object.freeze({
  Admin: 'ADMIN', // 시스템 관리자
  SubAdmin: 'SUB', // 관리자
  WineDataManager: 'WINEDATA', // pdata 등록/수정/삭제
  DataChecker: 'DATACHECKER', // pdata 검수
  Observer: 'OBSERVER' // 관찰자
});

/**
 * Product 판매 최대 금액
 */
export const MAX_PRODUCT_PRICE = 999999999;

/**
 * Product 재고 최대 수량
 */
export const MAX_PRODUCT_QUANTITY = 999999999;

/**
 * product_type 상품 유형
 */
export const PRODUCT_TYPES = Object.freeze({
  NORMAL: {
    name: '일반상품',
    value: 'NORMAL'
  },
  TDBOTTLE: {
    name: '오늘의 한 병',
    value: 'TDBOTTLE'
  },
  COBUY: {
    name: '공동구매',
    value: 'COBUY'
  },
  PROMOTION_RESERVE: {
    name: '예약판매',
    value: 'PROMO_RESERVE'
  }
  // PROMOTION_PRODUCT: {
  //   name: '프로모션',
  //   value: 'PROMO_PRODUCT'
  // },
  // PROMOTION_PDATA: {
  //   name: '프로모션',
  //   value: 'PROMO_PDATA'
  // }
});

/**
 * product_type 값으로 카테고리 조회
 */
export const findProductTypeByValue = (value) => {
  let type = null;
  for (const [, c] of Object.entries(PRODUCT_TYPES)) {
    if (c.value === value) type = { ...c };
  }
  if (type === null) console.warn(`알 수 없는 product_type 값[${value}]`);
  return type;
};

/**
 * product_type 값으로 product_type명 조회
 * @param {string} pdataType
 */
export const findProductTypeNameByValue = (productType) => findProductTypeByValue(productType)?.name ?? productType;

/**
 * pdata 카테고리
 * @type {{WINE: {name: string, value: string}}}
 */
export const PDATA_CATEGORIES = Object.freeze({
  WINE: {
    name: '와인',
    value: 'wine',
    color: '#fbe9e7',
    chipColor: 'orange'
  },
  TICKET: {
    name: '티켓',
    value: 'ticket',
    color: '#b9f6ca',
    chipColor: 'secondary'
  },
  WHISKEY: {
    name: '위스키',
    value: 'whiskey',
    color: '#fbf5ba',
    chipColor: 'warning'
  },
  NIHONSHU: {
    name: '일본주',
    value: 'nihonshu',
    color: '#e3f2fd',
    chipColor: 'success'
  },
  OFFER: {
    name: '기획상품',
    value: 'offer',
    color: '#e3f2fd',
    chipColo: 'grey'
  }
});

/**
 * pdata 카테고리 값으로 카테고리 조회
 * @param value
 * @returns {null}
 */
export const findPdataCategoryByValue = (value) => {
  let category = null;
  for (const [, c] of Object.entries(PDATA_CATEGORIES)) {
    if (c.value === value) category = { ...c };
  }
  if (category === null) console.debug(`알 수 없는 카테고리 값[${value}]`);
  return category;
};

/**
 * pdata 카테고리 값으로 pdata 카테고리명 조회
 * @param {string} pdataType
 */
export const findPdataCategoryNameByValue = (pdataType) => findPdataCategoryByValue(pdataType)?.name ?? pdataType;

/**
 * pdata 카테고리 값으로 pdata 색상 조회
 * @param {string} pdataType
 */
export const findPdataCategoryColorByValue = (pdataType) => findPdataCategoryByValue(pdataType)?.color ?? pdataType;

/**
 * pdata 카테고리 값으로 pdata chipColor 조회
 */
export const findPDataCategoryChipColorByValue = (pdataType) => findPdataCategoryByValue(pdataType)?.chipColor ?? pdataType;

/**
 * 와인용량 number to string
 * @param {number} capacity 용량
 * @returns {string} 단위화된 용량
 */
export const bottleCapacityToString = (capacity) => {
  const _capacity = typeof capacity !== 'number' ? Number(capacity) : capacity;
  if (Number.isNaN(_capacity)) {
    console.debug(`[Calculator:formatCapacity#1] 와인용량 formatting 실패. [capacity='${capacity}']`);
    return '';
  }
  let str;
  try {
    if (_capacity >= 1000) {
      if (_capacity % 1000 === 0) {
        // 1000, 2000, 3000 -> 1L, 2L, 3L
        str = `${(_capacity / 1000).toFixed(0)}L`;
      } else {
        // 1250 -> 1.2L
        // 1025 -> 1.02L
        str = `${(_capacity / 1000).toFixed(_capacity % 1000 >= 100 ? 1 : 2)}L`;
      }
    } else {
      str = `${_capacity}ml`;
    }
  } catch (e) {
    console.debug(`[Calculator:formatCapacity#2] 와인용량 formatting 실패. [capacity='${capacity}']`);
    str = '';
  }

  return str;
};

/** 와인타입 */
export const WINE_TYPES = Object.freeze({
  /** 레드 */
  RED: { value: 'RED', label: { ko: '레드', en: 'Red' } },
  WHITE: { value: 'WHITE', label: { ko: '화이트', en: 'White' } },
  SPARKLING: { value: 'SPARKLING', label: { ko: '스파클링', en: 'Sparkling' } },
  ROSE: { value: 'ROSE', label: { ko: '로제', en: 'Rose' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/**
 * 상품 빈티지
 * 범위 : 1900 ~ 현재년도 + 1
 *
 * @returns {[]}
 */
export const WINE_VINTAGES = (() => {
  const date = new Date();
  const year = date.getFullYear();

  const START_VINTAGE = 1900;
  const END_VINTAGE = year + 1;

  const vintageSection = [];
  vintageSection.push({ value: 'NV', label: 'NV' });

  for (let i = END_VINTAGE; i >= START_VINTAGE; i -= 1) {
    const strYear = String(i);
    vintageSection.push({ value: strYear, label: strYear });
  }

  return vintageSection;
})();

export const PAGE_OPTIONS = [10, 15, 30, 50, 75, 100];

/**
 * 입점샵 영업시간
 * 범위 : 24시
 *
 * @returns {[]}
 */
export const BIZ_HOUR = (() => {
  // 00시 ~ 24시
  const result = [
    { value: 0, label: '밤 12시' },
    { value: 1, label: '오전 1시' },
    { value: 2, label: '오전 2시' },
    { value: 3, label: '오전 3시' },
    { value: 4, label: '오전 4시' },
    { value: 5, label: '오전 5시' },
    { value: 6, label: '오전 6시' },
    { value: 7, label: '오전 7시' },
    { value: 8, label: '오전 8시' },
    { value: 9, label: '오전 9시' },
    { value: 10, label: '오전 10시' },
    { value: 11, label: '오전 11시' },
    { value: 12, label: '낮 12시' },
    { value: 13, label: '오후 1시' },
    { value: 14, label: '오후 2시' },
    { value: 15, label: '오후 3시' },
    { value: 16, label: '오후 4시' },
    { value: 17, label: '오후 5시' },
    { value: 18, label: '오후 6시' },
    { value: 19, label: '오후 7시' },
    { value: 20, label: '오후 8시' },
    { value: 21, label: '오후 9시' },
    { value: 22, label: '오후 10시' },
    { value: 23, label: '오후 11시' }
  ];
  return result;
})();

/**
 * 입점샵 영업시간
 * 범위 : 60분 (10분 단위)
 *
 * @returns {[]}
 */
export const BIZ_MINUTE = (() => {
  // 00분 ~ 60분 (10분 단위)
  const result = [
    { value: 0, label: '0분' },
    { value: 10, label: '10분' },
    { value: 20, label: '20분' },
    { value: 30, label: '30분' },
    { value: 40, label: '40분' },
    { value: 50, label: '50분' }
  ];
  return result;
})();

export const CLO_CODE = {
  /* 7200 - 주요화면 오류 */
  /** 주요 컨텐츠 관련 오류 */
  IMPORTANT_CONTENT_ERROR: 7200,
  /** 주문조회 오류 */
  ORDER_LOAD_ERROR: 7201,
  ORDER_REFUND_FETCH_ERROR: 7301,
  ORDER_REFUND_COMMISION_FETCH_ERROR: 7302,
  /** 주문 상품의 상태값 변경 오류 */
  ORDER_PRODUCT_REQUEST_ERROR: 7501,
  /** 알 수 없거나 정의되지 않은 기타 에러 */
  UNEXPECTED_ERROR: 7999
};

// User App Dynamic Base Url
export const USER_DYNAMIC_BASE_URL = `https://api${SERVICE_TYPE === 'production' ? '' : '-dev'}.1kmwine.kr/link/user`;

// User App Url 타입 정의
export const USER_DYNAMIC_URL_TYPE = {
  EVENT: 'event', // 이벤트
  VENDOR: 'vendor', // 입점샵
  THEME: 'curation-card', // 테마 상품
  MAIN_CONTENTS: 'main-content' // 메인 콘텐츠
};

/**
 * 주문 관련 상태 정의
 */
export const ORDER = Object.freeze({
  STATE: {
    /** 결제중 */
    ING: 'ING',
    /** 승인요청 */
    REQ: 'REQ',
    /** 주문취소 (사용자 취소) */
    CANCEL: 'CANCEL',
    /** 거절된 주문 */
    REQ_NO: 'REQ_NO',
    /** 승인된 주문 */
    REQ_OK: 'REQ_OK',
    /** 픽업 완료 */
    PICK_OK: 'PICK_OK',
    /** 입고 준비(공동 구매 전용) */
    REQ_CONFIRM: 'REQ_CONFIRM'
  }
});

/**
 * 공동 구매 상태 정의
 */
export const COBUY = Object.freeze({
  STATE: {
    /** 일반 주문 */
    NONE: 'NONE',
    /** 대기 중 */
    WAIT: 'WAIT',
    /** 달성 성공 */
    SUCC: 'SUCC',
    /** 실패 */
    FAIL: 'FAIL'
  }
});

/**
 * 공동구매 상태 문자열로 반환
 */
export const getCobuyStateStr = (state) => {
  switch (state) {
    case COBUY.STATE.WAIT:
      return '대기 중';
    case COBUY.STATE.SUCC:
      return '달성 성공';
    case COBUY.STATE.FAIL:
      return '실패';
    default: // COBUY.STATE.NONE or something
      return '일반주문';
  }
};

/**
 * 주문 상품 관련 상태 정의
 */
export const ORDER_ITEM = Object.freeze({
  STATE: {
    /** 결제중 */
    ING: ORDER.STATE.ING,
    /** 승인요청 */
    REQ: ORDER.STATE.REQ,
    /** 거절된 주문 */
    REQ_NO: ORDER.STATE.REQ_NO,
    /** 승인된 주문 */
    REQ_OK: ORDER.STATE.REQ_OK,
    /** 주문취소 */
    CANCEL: 'CANCEL',

    /** 픽업완료 */
    PICK_OK: ORDER.STATE.PICK_OK,

    /** 부분 승인 */
    REQ_PART_OK: 'REQ_PART_OK',
    /** 구매확정 */
    CONFIRM: 'CONFIRM',

    /** 교환/반품 통합 */
    EXCHANGE_RETURN: 'EXCRTN',

    /** 반품요청 */
    REQ_RTN: 'REQ_RTN',
    /** 반품대기 */
    RTN_WAIT: 'RTN_WAIT',
    /** 반품취소 */
    RTN_NO: 'RTN_NO',
    /** 반품완료 */
    RTN_OK: 'RTN_OK',

    /** 교환요청 */
    REQ_EXC: 'REQ_EXC',
    /** 교환대기 */
    EXC_WAIT: 'EXC_WAIT',
    /** 교환취소 */
    EXC_NO: 'EXC_NO'
    /* 교환완료 = PICK_UP_DONE */
  }
});

export const WINEPEDIA_HOST = process.env.REACT_APP_SERVICE_TYPE === 'production' ? 'https://www.1kmwine.kr' : 'https://www-dev.1kmwine.kr';

/**
 * URL 공유하기가 가능한 pdata category
 * @type {Readonly<(string|string)[]>}
 */
export const SHAREABLE_PDATA_CATEGORIES = Object.freeze([
  PDATA_CATEGORIES.WINE.value,
  PDATA_CATEGORIES.WHISKEY.value,
  PDATA_CATEGORIES.NIHONSHU.value,
  PDATA_CATEGORIES.OFFER.value
]);

export const KmPromotionType = Object.freeze({
  RESERVE: 'reserve', // 예약판매
  PRODUCT: 'product', // 상품 프로모션
  PDATA: 'pdata' // pdata 프로모션
});

export const getKmPromotionTypeStr = (type) => {
  switch (type) {
    case KmPromotionType.RESERVE:
      return '예약판매';
    case KmPromotionType.PRODUCT:
      return '상품 프로모션';
    case KmPromotionType.PDATA:
      return 'pdata 프로모션';
    default:
      return type;
  }
};

export const KmPrmOrdrStatePart = Object.freeze({
  /**
   * 예약판매 주문완료 상태
   */
  RSRV_REQ: 'PRM_REQ_OK',
  RSTATE_WAIT: 'PRM_RSTATE_WAIT',
  RSTATE_CONFIRM: 'PRM_RSTATE_CONFIRM',
  RSTATE_OK: 'PRM_RSTATE_OK'
});

export const KmPromotionState = Object.freeze({
  Draft: 'DRAFT',
  Ok: 'OK'
});
export const KmPromotionStateLabel = Object.freeze({
  [KmPromotionState.Draft]: '임시저장',
  [KmPromotionState.Ok]: '진행 중'
});
