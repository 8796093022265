/*
 * Side Bar - 통계 관리
 */

import { Part } from 'config';
import { IconChartArrowsVertical } from '@tabler/icons';

// ==============================|| STATISTICS MENU ITEMS ||============================== //

const statistics = {
  id: 'statistics',
  title: '',
  type: 'group',
  part: [Part.Admin],
  children: [
    {
      id: 'statisticsTab',
      title: '통계 관리',
      type: 'collapse',
      children: [
        {
          id: 'notificationManage',
          title: '알림 통계',
          type: 'item',
          url: '/notification',
          icon: IconChartArrowsVertical,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default statistics;
