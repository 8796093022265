/*
 * Side Bar - [임시]
 */

// assets
import { IconChartArcs, IconChartInfographic, IconClipboardList } from '@tabler/icons';
import { Part } from '../config';

// constant
const icons = {
  IconChartArcs,
  IconClipboardList,
  IconChartInfographic
};

// ==============================|| PRODUCT MENU ITEMS ||============================== //

const tempSideBar = {
  id: 'tempSideBar',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'tempSideBarTab',
      title: '[임시]',
      type: 'collapse',
      part: [Part.Admin, Part.SubAdmin],
      children: [
        /**/
        {
          id: 'tempDataManage',
          title: '[데이터 작성]',
          type: 'item',
          url: '/data/wine',
          icon: icons.IconChartArcs,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default tempSideBar;
