import React, { memo } from 'react';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';

// material-ui
import { Box, CircularProgress, Typography } from '@mui/material';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const auth = useAuth();

  const navItems = menuItem.items.map((item) => {
    // part 설정이 없으면 모든 user.part에 대해서 메뉴가 공개됨
    if (!item.part || item.part.includes(auth?.user?.part)) {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    }
    return null;
  });

  if (!auth.isInitialized) {
    return (
      <Box py={3} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="caption">메뉴권한 확인 중</Typography>
        <CircularProgress size="8px" color="inherit" sx={{ ml: '4px' }} />
      </Box>
    );
  }
  return <>{navItems}</>;
};

export default memo(MenuList);
