/*
 * Side Bar - 데이터 관리
 */

import { IconBottle, IconListDetails, IconPinned } from '@tabler/icons';
import { Part } from '../config';

// ==============================|| DATA MENU ITEMS ||============================== //

const data = {
  id: 'data',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dataTab',
      title: '데이터 관리',
      type: 'collapse',
      children: [
        {
          id: 'dataAlcoholManage',
          title: '주류관리',
          type: 'item',
          url: '/data/wine',
          icon: IconBottle,
          breadcrumbs: true
        },
        {
          id: 'dataErpItemList',
          title: 'ERP상품 연동관리',
          type: 'item',
          url: '/data/erp/item/list',
          icon: IconBottle,
          breadcrumbs: false,
          part: [Part.Admin, Part.SubAdmin, Part.WineDataManager]
        },
        {
          id: 'dataLocationPinManage',
          title: '위치 핀 관리',
          type: 'item',
          url: '/data/pin',
          icon: IconPinned,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        },

        // {
        //   id: 'dataMessageTemplateManage',
        //   title: '메세지 템플릿 관리',
        //   type: 'item',
        //   url: '/data/message-template',
        //   icon: IconListDetails,
        //   breadcrumbs: true,
        //   part: [Part.Admin, Part.SubAdmin]
        // },
        // {
        //   id: 'dataCampaignGoodsMappingInfo',
        //   title: '캠페인 상품현황 조회',
        //   type: 'item',
        //   url: '/data/narapos/campaign-goods',
        //   icon: IconListDetails,
        //   breadcrumbs: true,
        //   part: [Part.Admin, Part.SubAdmin, Part.WineDataManager]
        // }
        // {
        //   id: 'dataNaverCpcEpFile',
        //   title: '네이버 EP파일 생성기',
        //   type: 'item',
        //   url: '/data/naver-ep',
        //   icon: IconListDetails,
        //   breadcrumbs: false,
        //   part: [Part.Admin, Part.SubAdmin]
        // }
        {
          id: 'dataErpCampaignList',
          title: '나라포스 캠페인관리',
          type: 'item',
          url: '/data/erp/campaign/list',
          icon: IconListDetails,
          breadcrumbs: false,
          part: [Part.Admin, Part.SubAdmin]
        }
      ]
    }
  ]
};

export default data;
