/*
 * Side Bar - 회원 관리
 */

import { Part } from 'config';
import { IconUser, IconUserX } from '@tabler/icons';

// ==============================|| USER MENU ITEMS ||============================== //

const user = {
  id: 'user',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'userTab',
      title: '회원 관리',
      type: 'collapse',
      children: [
        {
          id: 'userManage',
          title: '회원 관리',
          type: 'item',
          url: '/user',
          icon: IconUser,
          breadcrumbs: true
        },
        {
          id: 'adminGroupManage',
          title: '탈퇴회원 관리',
          type: 'item',
          url: '/user/leave',
          icon: IconUserX,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default user;
