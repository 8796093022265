import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import wineOneReducer from 'store/slices/wine-one';

const WineOneContext = createContext();

/**
 * 와인원(1km wine) 입점사 웹앱 프로바이더
 * @constructor
 * @deprecated  TODO
 */
function WineOneProvider({ children }) {
  const [state, dispatch] = useReducer(wineOneReducer, null);

  return <WineOneContext.Provider value={{ ...state }}>{children}</WineOneContext.Provider>;
}

WineOneProvider.propTypes = {
  children: PropTypes.node
};

export { WineOneProvider, WineOneContext };
