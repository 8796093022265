/*
 * Side Bar - 문의 관리
 */

import { Part } from 'config';
import { IconBrandMessenger } from '@tabler/icons';

// ==============================|| INQUIRY MENU ITEMS ||============================== //

const inquiry = {
  id: 'inquiry',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'inquiryTab',
      title: '문의 관리',
      type: 'collapse',
      children: [
        {
          id: 'vendorInquiryManage',
          title: '입점샵 문의관리',
          type: 'item',
          url: '/inquiry/vendor',
          icon: IconBrandMessenger,
          breadcrumbs: true
        }
      ]
    }
  ]
};

export default inquiry;
